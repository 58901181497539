<template>
  <div class="planner w-100 px-3 pb-3 overflow-hidden">
    <planner-filters :list-data-obj="listDataObj" :manage="listDataObj.manage"></planner-filters>
    <div v-if="isLoading" class="loading-planner">
      <h1>Loading Planner, Please wait....</h1>
    </div>
    <div v-if="!isLoading" class="planner-scrollabe-div">
      <!-- https://www.npmjs.com/package/vuedraggable -->
      <draggable class="w-100 d-flex" tag="div" v-model="header" v-bind="dragOptions" @start="dragBlock = true" @end="dragBlock = false">
        <div class="block col-sm-3 col-12  list-group-item dash-grey-card mb-3 mr-2 px-2 height-fit scrollable-block" :class="block.borderClass" v-for="block in header" :key="block.id">
          <h5 class="mb-1 font-weight-bold">{{ block.title }}</h5>
          <small class="mb-3 d-block">{{ block.subTitle }}</small>
          <draggable class="list-group" tag="div" :list="block.list" :group="{name: 'list'}" v-bind="dragOptions" @start="dragItem = true" @end="dragItem = false">
            <div class="list-item list-group-item mb-3" :class="list.borderClass" v-for="list in block.list" :key="list.id">
              <div class="d-flex justify-content-between">
                <small class="font-weight-bold">{{ list.id }}</small>
                <small>{{ list.modifiedDate | formatDate }}</small>
              </div>
              <small class="font-weight-bold">{{ list.client }}</small>
              <small class="d-block mt-2">{{ list.location }}</small>
              <small class="mt-2 font-weight-bold d-block ss-primary-font-color">${{ list.total }}</small>
              <div class="mt-2 d-flex justify-content-between">
                <div :class="`badge c-badge--${list.badgeStatusClass} c-badge--small`">{{ list.status }}</div>
                <a href="#" class="link" @click.prevent="showPanel({id: 890})">Edit</a>
              </div>
            </div>
          </draggable>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
  import plannerFilters from "@/components/planner/plannerFilters.vue";
  import draggable from "vuedraggable";
  import ApplicationService from "@/services/application";
  import SalesService from "@/services/sales";
  const sales = new SalesService();
  const app = new ApplicationService();
  export default {
    props: {
      listDataObj: {
        type: Object,
        required: true,
      },
    },
    components: {
      draggable,
      plannerFilters,
    },
    data() {
      return {
        // test: [
        //   {
        //     id: 1,
        //     title: "New",
        //     borderClass: "border-top-orange",
        //     list: [
        //       {cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 1, borderClass: "border-left-orange", client: "Introspect Technology", total: "354,000.00"},
        //       {cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 2, borderClass: "border-left-orange", client: "Introspect Technology", total: "354,000.00"},
        //       {cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 3, borderClass: "border-left-orange", client: "Introspect Technology", total: "354,000.00"},
        //       {cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 4, borderClass: "border-left-orange", client: "Introspect Technology", total: "354,000.00"},
        //     ],
        //   },
        //   {
        //     id: 2,
        //     title: "Sent to Client",
        //     borderClass: "border-top-blue",
        //     list: [
        //       {cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 5, borderClass: "border-left-blue", client: "Introspect Technology", total: "354,000.00"},
        //       {cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 6, borderClass: "border-left-blue", client: "Introspect Technology", total: "354,000.00"},
        //     ],
        //   },
        //   {
        //     id: 3,
        //     title: "Approved by Client",
        //     borderClass: "border-top-green",
        //     list: [
        //       {cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 7, borderClass: "border-left-green", client: "Introspect Technology", total: "354,000.00"},
        //       {cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 8, borderClass: "border-left-green", client: "Introspect Technology", total: "354,000.00"},
        //       {cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 9, borderClass: "border-left-green", client: "Introspect Technology", total: "354,000.00"},
        //     ],
        //   },
        //   {
        //     id: 4,
        //     title: "Approved by Manager",
        //     borderClass: "border-top-purple",
        //     list: [
        //       {cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 10, borderClass: "border-left-purple", client: "Introspect Technology", total: "354,000.00"},
        //       {cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 11, borderClass: "border-left-purple", client: "Introspect Technology", total: "354,000.00"},
        //     ],
        //   },
        //   {
        //     id: 5,
        //     title: "Cancelled",
        //     borderClass: "border-top-red",
        //     list: [
        //       {cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 10, borderClass: "border-left-red", client: "Introspect Technology", total: "354,000.00"},
        //       {cretedBy: "Dwain Browne", location: "205-88555 Granville Street, Vancouver", id: 11, borderClass: "border-left-red", client: "Introspect Technology", total: "354,000.00"},
        //     ],
        //   },
        // ],
        dragBlock: false,
        dragItem: false,
        isLoading: true,
        header: [],
      };
    },
    methods: {
      checkListMove(e) {
        // console.log(`list`, e);
      },
      checkBlockMove(e) {
        // console.log(`block`, e);
      },
    },
    computed: {
      dragOptions() {
        return {
          animation: 200,
          disabled: false,
          ghostClass: "ghost",
          chosenClass: "selectedListItem",
        };
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      async init() {
        this.isLoading = true;
        const header = await sales.getPlannerHeader();
        if (header) {
          for (const h of header) {
            const res = await sales.getPlannerListByHeader({
              headerId: h.id,
            });
            const index = header.findIndex((d) => d.id === h.id);
            const result = res && res.length > 0 ? res : [];
            this.$set(header[index], "list", result);
          }
          this.header = header;
          this.isLoading = false;
        }
      },
      showPanel(data) {
        if (!data) return;
        //Get the Route data passed from the controller
        let route = this.listDataObj.editAction.route;

        //Set Id of the currently selected/edit table item
        let currentPath = route.path + "/" + data.id;
        route.param.id = data.id;

        //Update the router
        if (currentPath !== this.$route.path) {
          this.$router.push({name: `${route.name}`, params: {id: `${data.id}`}});
        }
        //Pass to show panel for further processing
        app.showSidePanel(this.listDataObj, data, route);
      },
    },
  };
</script>

<style scopped>
  .planner .flip-list-move {
    transition: transform 0.5s;
  }

  .planner .no-move {
    transition: transform 0s;
  }

  .ghost,
  .selectedListItem {
    background: #9404a188;
    /* background: #383638 ; */
    /* width:250px; */
  }

  .planner .list-group {
    min-height: 20px;
  }

  .planner .list-group-item {
    border-radius: 4px;
    cursor: move;
  }

  .planner .list-group-item:not(.block) {
    border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
    border-radius: 0px 4px 4px 0px;
  }

  .planner .block {
    border: inherit;
    border-radius: 4px;
  }

  .height-fit {
    height: fit-content;
  }

  .planner-scrollabe-div {
    overflow-x: scroll !important;
    overflow-y: hidden;
    height: calc(100vh - 225px);
  }

  .planner-scrollabe-div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3) !important;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3) !important;
    border-radius: 3px !important;
    background-color: #f5f5f5 !important;
  }

  .planner-scrollabe-div::-webkit-scrollbar,
  .scrollable-block::-webkit-scrollbar {
    background-color: #f5f5f5 !important;
    height: 15px;
    width: 5px;
  }

  .planner-scrollabe-div::-webkit-scrollbar-thumb,
  .scrollable-block::-webkit-scrollbar-thumb {
    border-radius: 3px !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5) !important;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5) !important;
    background-color: #6e0270 !important;
  }

  .scrollable-block {
    min-height: 200px;
    max-height: calc(100vh - 250px);
    overflow-y: auto !important;
  }
</style>
