<template>
  <b-card no-body class="mb-3 px-3 pt-3">
    <div class="row mb-2">
      <div class="col-12">
        <div class="row">
          <div class="col pr-0 mb-2">
            <input class="form-control" type="text" placeholder="Search Quote No." />
          </div>
          <div class="col pr-0 mb-2">
            <input class="form-control" type="text" placeholder="Prospect/Client/Address" />
          </div>
          <div class="col pr-0 mb-2">
            <v-select class="bg-white" placeholder="Status" :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
          </div>
          <div class="col pr-0 mb-2">
            <v-select class="bg-white" placeholder="Sales Rep" :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
          </div>
          <div class="col pr-0 mb-2">
            <v-select class="bg-white" placeholder="Created By" :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
          </div>
          <div class="col pr-0 mb-2">
            <v-select class="bg-white" placeholder="Stage" :options="['abc', 'cde', 'fgh']" label="Select" :multiple="true"></v-select>
          </div>

          <div v-if="manage" class="col pr-0 mb-2">
            <b-dropdown
              v-if="manage.isManageDropdownEnabled"
              id="details-manage-btn-dropdown"
              split
              ref="dropdown"
              size="sm"
              class="split-drop-down split-drop-down-info h-41"
              @click="onActionItemClicked(manage.defaultAction)"
            >
              <template v-slot:button-content> <span class="fa fa-pencil mr-2 text-light"></span> Manage </template>

              <b-dropdown-item v-for="action in manage.actions" v-bind:key="action.id" @click="onActionItemClicked(action)">{{ action.menuTitle }}</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
  import ApplicationService from "@/services/application";
  const app = new ApplicationService();
  export default {
    props: {
      listDataObj: {
        type: Object,
        required: true,
      },
      manage: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {};
    },
    components: {},
    methods: {
      onActionItemClicked(action) {
        //Pass action to global navigation action handler...
        app.actionNavigation(action);
      },
    },
  };
</script>
